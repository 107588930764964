import React from "react";
import styled from "styled-components";

const Missionp = styled.p`
    background: #eaeaea;
    color: #121212;
`

export default function EniltrexMission() {
    return <div>
        <Missionp>Convert your projects in a source of income</Missionp>
    </div>
}