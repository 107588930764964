import React from 'react';
import ReactDOM from 'react-dom';
import IndexPage from "./pages";





ReactDOM.render(
    <IndexPage />,
    document.getElementById('root')
);