import React from 'react';

import styled from "styled-components";

const EniltrexTitleH1 = styled.h1`
    background: #121212;
    color: #fff;
`

export default function EniltrexTitle() {
    return <EniltrexTitleH1>Eniltrex (name TBD)</EniltrexTitleH1>
}