import * as React from "react"
import EniltrexTitle from "../components/eniltrex-title";
import EniltrexMission from "../components/mission";
import Section from "../components/section";

// styles
const pageStyles = {
  color: "#232129",
  padding: 12,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <EniltrexTitle/>
      <EniltrexMission/>
      <Section name="Projects" text = "Find here all the projects/games developed here."/>
      <Section name="Services" text = "Deploy your game, add adds, use our infra, or more."/>
      <Section name="Consultancy" text = "Our best minds at your service."/>
      <Section name="Custom project" text = "We can digitalize your business."/>
      <Section name="Open Source" text = "Use our packages for free."/>
      <Section name="Assets Shop" text = "Buy the latest SVG from our amazing artist. (not the one in charge of the design of this site)"/>
      <Section name="Only fans" text = "Under consideration."/>
    </main>
  )
}

export default IndexPage
