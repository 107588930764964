import React from 'react';
import styled from "styled-components";

const SectionWrapper = styled.div`
`

const SectionTitleH2 = styled.h2`
    background: #bb66aa;
`

const SectionDescriptionP = styled.p`

`

interface sectionProps {
    name: string
    text: string
}

interface sectionTitleProps {
    name:string
}

interface sectionDescriptionsProps {
    description:string
}

export default function Section(props:sectionProps) {
    return <SectionWrapper>
        <SectionTitle name ={props.name} />
        <SectionDescription description={props.text} />
    </SectionWrapper>
}

function SectionTitle (props:sectionTitleProps) {
    return <SectionTitleH2>{props.name}</SectionTitleH2>
}

function SectionDescription(props: sectionDescriptionsProps) {
    return <SectionDescriptionP>{props.description}</SectionDescriptionP>
}